export const TermsPrivacyPolicy = [
  {
    english: {
      title: '1. Privacy Policy',
      items: [
        '9.1 We claim no intellectual property rights over the material you provide or upload to the Service. All materials, Data and Content uploaded remain yours.',
        '9.2 The Service will retain your data as long as your account is active.',
        '9.3 The Company will not be responsible for any deletions of data by you or your users. All such deletions are permanent',
        '9.4 The Company does not access Customer’s data unless required to provide the Service or Support. Any such access is limited to personnel who require access to provide the Service and/or Support.',
        '9.5 The Company may terminate your account and delete any content contained in it if there is no account activity including log in, payment for over 12 months. We may warn you by email before such an event in order to give you opportunity to keep the account active by logging in or re-subscribing to the Services.',
        '9.6 The Customer may request deletion of data in writing to the Company from the Service. Data Deletion requests will be processed in 10 Calendar Days.',
      ],
    },
    arabic: {
      title: '١. سياسة الخصوصية',
      items: [
        '١.٩. الشركة لا تملك حقوق ملكية فكرية فيما يخص المعلومات والمواد التي يتم تداولها وإدخالها لنظام الخدمة، كل هذه المعلومات تبقى ملكية خاصة بمستخدم خدمات مليور. ',
        '٢.٩. تحتفظ الشركة بمعلومات المستخدم طالما بقى الحساد نشط وفعال. ',
        '٣.٩ الشركة وفريق المطورين غير مسئولين عن أي مسح للمعلومات يتم بمعرفة المستخدم أو أي من أفراد فريقه. أي مسح نهائي لمعلومات من قبل فريق المستخدم قد يستحيل معه إسترجاع تلك البيانات',
        '٤.٩ الشركة لا تراقب أو تتيح الوصول للمعلومات المدخلة من قبل المستخدم عدا في حالات طلب المستخدم نفسه ذلك كجزء من عملية الدعم الفني المطلوبة من قبل المستخدم. الوصول للمعلومات يبقى محصورا على أعضاء فريق الدعم الفني.',
        '٥.٩ للشركة الحق في إلغاء الحساب ومسح المعلومات المحتواة في الحساب في حالة عدم حدوث أي نشاط على الحساب أو دفع قيمة الإشتراك لمدة ١٢ شهر. قد يقوم فريق الشركة بإخطار المستخدم قبل حدوث المسح لإعطاء الفرصة للمستخدم لإعادة تفعيل الإشتراك أو حفظ المعلومات قبل حدوث المسح.',
        '٦.٩ للمستخدم الحق في تقديم طلب كتابي لمسح معلوماته من حساب الخدمة. تفعيل ذلك الطلب قد يستغرق ١٠ أيام عمل.',
      ],
    },
  },
]
