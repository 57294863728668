import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivacyPolicy from '../components/privacyPolicy'

import { ParallaxProvider } from 'react-scroll-parallax'

const PrivacyPolicyPage = ({ className }) => {
  const lang = 'en'
  return (
    <Layout lang={lang}>
      <SEO title="Privacy Page" />
      <ParallaxProvider>
        <PrivacyPolicy />
      </ParallaxProvider>
    </Layout>
  )
}

export default PrivacyPolicyPage
