import React from 'react'
import {FlexContainer, FormRow, OutlineCircle} from '../elements'
import styled from 'styled-components'

const section = ({english, arabic}) => {
    return (
    <FlexContainer direction="row" justifyContent="space-between" width="100%" flexWrap="wrap">
        <FlexContainer direction="column" className="row">

            <h3 dir="auto">
                {english["title"]}
            </h3>
            {
                english.description &&
                english.description.map(d => <p dir="auto" key={d}>{d}</p>)
            }
            <ul dir="auto">
            {
                english.items &&
                english.items.map(i =>
                    <li key={i}>
                        {i}
                    </li>
                    )
            }
            </ul>
        </FlexContainer>
        <FlexContainer direction="column" className="row">

            <h3 dir="auto">
                {arabic["title"]}
            </h3>
            {
                arabic.description && 
                arabic.description.map(d => <p dir="auto" key={d}>{d}</p>)

            }
            <ul dir="auto">
            {
                arabic.items &&
                arabic.items.map(i =>
                    <li key={i}>
                        {i}
                    </li>
                    )
            }
            </ul>
        </FlexContainer>
        <FormRow>
            <OutlineCircle delay="0.4s" fill="alternate" reverse="1"  duration="30s"top="0px" right="-1rem" size="47" thickness="3" innerposition="1.5px"/>
          </FormRow>
            <OutlineCircle delay="0.9s" fill="alternate"  duration="30s "top="0px" left="-1rem" size="112" thickness="3" innerposition="1.5px"/>

          < FormRow>
            <OutlineCircle  delay="0.5" reverse="1" duration="25s" top="1rem" right="-6.5rem" size="72" small_left="2rem" small_top="2rem" thickness="3" innerposition="1.5px"/>
        
            <OutlineCircle  delay="0" duration="25s" top="1rem" left="-6.5rem" size="112" small_left="10rem" small_top="10rem" thickness="3" innerposition="1.5px"/>
          </FormRow>
    </FlexContainer>

        
    )
}

export default styled(section)`
     .row{
         width: 47%;
     }
 @media (max-width: 620px) {
     .row{
         width: 100%;
     }
 } 
`