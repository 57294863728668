import React, { useState } from 'react'
import styled from 'styled-components'

import {
  FlexContainer,
  Container,
  FormRow,
  FilledCircle,
  OutlineCircle,
} from '../elements'

import { TermsPrivacyPolicy } from '../CMS/TermsPrivacyPolicy'
import Gear from './gear'
import Terms from './Terms-section'

const PrivacyPolicy = ({ className }) => {
  const [sections] = useState(TermsPrivacyPolicy)

  return (
    <Container className={className}>
      <FlexContainer flexWrap="wrap" justifyContent="space-between">
        <div className="row">
          <OutlineCircle
            top="-30px"
            right="1.4rem"
            size="142"
            small_top="23rem"
            small_right="5rem"
            duration="30s"
            delay="0.5s"
            thickness="3"
            innerposition="1.6px"
          />
          <h1 className="ContactpageTitle">
            Terms & <br /> Conditions
            <FilledCircle top="-10px" left="6.4rem" size="70px" duration="5s" />
          </h1>
          <p>
            The following terms constitute an agreement between you (“Customer”,
            “User”, “Your”, or “You”) and Melior for feedback and complaints
            management represented by Shamseya for Innovative Healthcare
            Solutions, a Private Limited Liability Company (“Company”) which is
            the exclusive licenser, manager, and operator of Melior Services
            (“we”, “us” or “our”).
          </p>
          <p>
            This Agreement (“Terms of Service “, “Agreement”) governs your use
            of Mymelior.com site (“Melior Website”), cloud-based Software as a
            Service(SaaS) application and Mobile/Tablet apps distributed
            directly or through different application stores and platforms and
            (“Melior Application” or “Service” or “Services”) as a casual
            browser, registered user, trial user and/or subscriber (“Authorized
            Users”) of Services which you access by signing in with an email and
            password.
          </p>
          <p>
            Company reserves the right to update and change these Terms of
            Service without notice. By accessing or using this site and the
            software on website and tablets, you acknowledge that you have read,
            understood and agree to abide and comply with all terms and
            conditions contained herein (the “Terms of Use”). If you do not
            accept the terms of this agreement, do not use the services.
          </p>
          <p>In order to use these services, you must :</p>
          <ol>
            <li>Complete the registration process</li>
            <li>Agree to these Terms of Use</li>
            <li>
              Provide Complete, True and Up to Date Contact Information, to the
              best of your knowledge
            </li>
          </ol>
          <Gear
            size="70"
            top="28%"
            right="4rem"
            small_top="40rem"
            left="15rem"
          />
          <Gear
            size="40"
            top="33%"
            right="3rem"
            small_top="5rem"
            rigth="10rem"
          />
          <Gear size="100" top="42%" left="-1rem" small_left="-2rem" />
          <Gear
            size="90"
            top="92%"
            right="4rem"
            small_top="55rem"
            rigth="5rem"
          />
          <Gear size="90" top="20%" left="-5rem" small_left="-2rem" />
          <Gear
            size="60"
            top="2.5rem"
            left="-8rem"
            small_top="-3rem"
            small_left="2rem"
          />
        </div>
        <OutlineCircle
          top="20%"
          right="40%"
          size="142"
          fill="alternare"
          duration="30s"
          delay="0.5s"
          thickness="3"
          innerposition="1.6px"
        />
        <OutlineCircle
          top="31%"
          left="30%"
          size="90"
          reverse="1"
          duration="30s"
          delay="0.25s"
          thickness="3"
          innerposition="1.6px"
        />
        <OutlineCircle
          top="77%"
          left="60%"
          size="120"
          duration="30s"
          delay="0.75s"
          thickness="3"
          innerposition="1.6px"
        />
        <OutlineCircle
          top="53%"
          right="55%"
          size="100"
          duration="30s"
          fill="alternare"
          reverse="1"
          thickness="3"
          innerposition="1.6px"
        />

        <div className="row">
          <OutlineCircle
            top="-30px"
            right="1.4rem"
            size="142"
            small_top="10rem"
            small_left="3rem"
            duration="30s"
            delay="0.5s"
            thickness="3"
            innerposition="1.6px"
          />
          <h1 className="ContactpageTitle" dir="auto">
            شروط الإستخدام
            <FilledCircle
              top="-10px"
              right="6.4rem"
              size="70px"
              duration="5s"
              small_top="65rem"
              medium_top="-10px"
            />
          </h1>
          <p dir="auto">
            الشروط التالية تمثل الإتفاق بينك كمستخدم لخدمات مليور لإدارة شكاوى
            وآراء العملاء (يشار إليك لاحقا بالآتي "العميل" "المستخدم") وفريق
            مطوري مليور والمثل عنهم شمسية لإدارة الأنظمة الصحية كشركة ذات
            مسئولية محدودة والتي تمتلك الحقوق الحصرية لإدارة وتشغيل وترخيص خدمات
            مليور(يشار إلينا لاحقا بالآتي "مقدم الخدمة" "الشركة" "فريق العمل").
          </p>
          <p dir="auto">
            هذه الشروط تحكم إستخدامك للموقع الإلكتروني mymelior.com وخدمات مليور
            المختلفة والتي تشمل تطبيقات إلكترونية توزع من خلال المنصات المتخصصة
            في توزيع التطبيقات الإلكترونية وخدمات أخرى معتمدة على الcloud, ذلك
            الاستخدام يشمل التصفح العادي، الإستخدام كعميل مسجل، الاستخدام
            التجريبي والاشتراك والخدمات التي يتم الحصول عليها من خلال تسجيل
            الدخول بإستخدام إسم المستخدم وكلمة السر.
          </p>
          <p dir="auto">
            يحتفظ مقدم الخدمة بحق تعديل بعض بنود الإتفاق بدون إخطار. استخدامك
            لوظائف وخدمات الموقع والتطبيقات الإلكترونية يعكس ضمنيا قراءتك وفهمك
            موافقتك على الإلتزام بمحتويات وشروط المتضمنة أدناه. إذا كنت ترفض
            شروط الإستخدام يمكنك الإمتناع عن إستخدام الخدمات المختلفة.
          </p>
          <p dir="auto">للبدأ في الإستفادة من الخدمات يجب أن:</p>
          <ol dir="auto">
            <li>تكمل كافة إجراءات التسجيل. </li>
            <li>وافقت على شروط الإستخدام</li>
            <li>زودت مقدمي الخدمة بمعلومات تواصل صحيحة ومحدثة ودقيقة.</li>
          </ol>
        </div>
      </FlexContainer>
      <FormRow>
        <Gear
          size="80"
          top="20%"
          right="10rem"
          small_top=""
          small_left="-2rem"
        />
        <Gear
          size="30"
          top="-15.5rem"
          right="8rem"
          small_top="-3rem"
          small_left="2rem"
        />
      </FormRow>
      {sections.map(section => (
        <Terms
          key={section.english.title}
          english={section.english}
          arabic={section.arabic}
        />
      ))}
      <Gear
        size="100"
        top="50%"
        left="14rem"
        small_top="70%"
        small_left="-4rem"
      />
      <Gear
        size="80"
        top="70%"
        right="4rem"
        small_top="30%"
        small_left="2rem"
      />
    </Container>
  )
}

export default styled(PrivacyPolicy)`
  .ContactpageTitle {
    height: 6rem;
  }
  .row {
    width: 43%;
  }
  @media (max-width: 620px) {
    .row {
      width: 100%;
    }
  }
`
